/* General styles */
body,
html {
	height: 100%;
	margin: 0;
	overflow-x: auto;
}

.App {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	text-align: center;
	background-color: #efefee;
	font-family: "Georgia", serif;
	font-weight: 300;
	color: #333;
}
h1,
h2,
h3 {
	text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}
p {
	line-height: 1.6;
	padding: 10px;
	margin: 15px 0;
}
.RouterProvider {
	flex: 1;
}

button {
	background-color: #4b654c;
	color: white;
	border: none;
	padding: 10px 20px;
	font-size: 1em;
	cursor: pointer;
	border-radius: 5px;
	transition: background-color 0.3s ease, transform 0.3s ease;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
	text-transform: uppercase;
	letter-spacing: 0.5px;
}

button:hover {
	background-color: #3d5240;
	transform: translateY(-2px);
}

button:active {
	background-color: #2e3f31;
	transform: translateY(0);
}

button:focus {
	outline: none;
	box-shadow: 0 0 0 3px rgba(75, 101, 76, 0.4);
}

/* Navigation styles */
.navigation-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 20px;
	background-color: #fff;
}

.navigation-logo {
	height: auto;
	cursor: pointer;
}

.nav-links {
	display: flex;
}

nav ul {
	display: flex;
	list-style: none;
	padding: 0;
	margin: 0;
	color: #b19780;
	font-size: larger;
	font-weight: bolder;
}

nav ul li {
	margin: 0 15px;
}

nav ul li a {
	text-decoration: none;
	color: #333;
}

nav ul li a:visited {
	color: #333;
}

nav ul li.active a {
	color: #3d5240; /* Darker shade for the active link */
	font-weight: bold;
	text-decoration: underline;
}

.hamburger-icon {
	display: none;
	cursor: pointer;
}

.book-now-mobile {
	display: none;
}

.book-now-desktop {
	display: flex;
	margin-left: 15px; /* Ensure some space between navigation and Book Now button */
}

/* Specific styles */
.page h1 {
	font-size: 2.5em;
	color: #333;
}

.page p {
	font-size: 1.2em;
	color: #666;
}

.navigation-container {
	height: 19vh;
	background-color: #a9ba9d;
}

.navigation-logo {
	align-self: center;
}
/* Page styles */
.page h1 {
	font-size: 2.5em;
	color: #333;
}

.page p {
	font-size: 1.2em;
	color: #666;
}

/* Navigation container styles */
.navigation-container {
	height: 19vh;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px 20px;
	background-color: #a9ba9d;
}

/* Logo styles */
.navigation-logo {
	align-self: center;
}

/* Social media icons container */
.social-media-icons {
	display: flex;
	justify-content: center;
	gap: 10px;
}

.social-media-icons a {
	color: #333; /* Default icon color */
	transition: color 0.3s ease;
}

.social-media-icons a:visited {
	color: inherit; /* Inherit the color specified for each specific class */
}

.social-media-icons a.facebook {
	color: #b19780;
}

.social-media-icons a.instagram {
	color: #b19780;
}

.social-media-icons a.email {
	color: #b19780; /* Change as needed */
}

.social-media-icons a:hover {
	color: #a9ba9d; /* Color on hover */
}

/* Footer styles */
.footer {
	display: flex;
	background-color: #f1f1f1;
	padding: 10px;
	text-align: center;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.footer nav ul li a {
	color: #b19780;
	font-size: small;
}

.footer nav ul li a:hover {
	color: #a9ba9d;
}

.footerNav {
	display: flex;
	flex-direction: column;
	background-color: #efefee;
}

/* Section styles */
.section {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	box-sizing: border-box;
}

/* Specific styles for AboutPage */
.section-about {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	box-sizing: border-box;
}

.section.gray {
	background-color: #efefef;
}

.section.kaki {
	background-color: #a9ba9d;
}

.div1,
.div2,
.div3,
.div4 {
	width: 100%;
}

.div3 h1 {
	text-align: center;
}

.div1,
.div3 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	text-align: justify;
	padding: 20px;
	box-sizing: border-box;
}

.div2,
.div4 {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 20px;
	box-sizing: border-box;
}

.div3 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	text-align: justify;
	padding: 20px;
	box-sizing: border-box;
}

.aboutTextIntro,
.additional-info p {
	margin: 0;
	width: 100%;
	padding-top: 20px;
}

.aboutPicture {
	width: 65%;
	object-fit: cover;
	border-radius: 50%;
	aspect-ratio: 1;
}

.aboutPictureOffice {
	width: 65%;
	object-fit: cover;
	border-radius: 20%;
	aspect-ratio: 1;
}

/* Grid Layout for ContactPage */
.contact-page {
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-template-rows: repeat(6, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
}

.contact-header {
	grid-area: 1 / 1 / 5 / 4;
	text-align: center;
	padding: 20px;
	background-color: #efefee;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contact-form-container {
	grid-area: 1 / 4 / 5 / 7;
	padding: 20px;
	background-color: #ffffff;
	display: flex;
	justify-content: center;
	align-items: center;
}

.contact-map-container {
	grid-area: 5 / 1 / 7 / 7;
	padding: 20px;
	background-color: #f0f0f0;
	display: flex;
	justify-content: center;
	align-items: center;
}

.contact-header h1 {
	font-size: 2.5em;
	color: #333;
	margin-bottom: 20px;
}

.contact-form-container form {
	width: 100%;
	max-width: 600px;
}

.contact-map-container {
	width: 100%;
}

.contact-form-container {
	background-color: #ffffff;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
	max-width: 600px;
	margin: 0 auto;
}

h2 {
	font-size: 2em;
	margin-bottom: 20px;
	color: #333;
}

form {
	display: flex;
	flex-direction: column;
}

.form-control {
	margin-bottom: 15px;
	display: flex;
	flex-direction: column;
	text-align: left;
}

label {
	font-size: 1em;
	margin-bottom: 5px;
	color: #666;
}

input[type="text"],
input[type="email"],
textarea {
	padding: 10px;
	border: 1px solid #ccc;
	border-radius: 4px;
	font-size: 1em;
}

textarea {
	resize: vertical;
}

.radio-group {
	display: flex;
	gap: 10px;
}

.radio-group input {
	margin-right: 5px;
}

.error {
	color: red;
	font-size: 0.9em;
	margin-top: 5px;
}

/* Specific styles for ServicesPage */
.section-services {
	height: 50vh;
}

.services-page {
	text-align: left;
}

.services-page h1 {
	text-align: center;
}

.section-divider {
	border: 0;
	height: 2px;
	background-color: black;
	margin: 0;
}

.divider-container {
	width: 100%;
}

.servicesDivFour {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
}

/* Specific styles for HomePage */
.section-about-home {
	flex-direction: column;
	padding-top: 10px;
}
.section-approach-home {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	box-sizing: border-box;
	flex-direction: column;
	background-color: #a9ba9d;
}

.homePicture {
	width: 35%;
	object-fit: cover;
	border-radius: 20%;
	aspect-ratio: 1;
	padding: 10px;
}

.homePictureOffice {
	width: 65%;

	max-width: 600px; /* Ensures the image does not exceed section width */
	object-fit: cover;
	border-radius: 20%;
	aspect-ratio: 1;
	margin: 0 auto;
}

.home-grid-container {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: repeat(4, 1fr);
	grid-column-gap: 0px;
	grid-row-gap: 0px;
	height: fit-content;
}

.home-new-clients-info {
	grid-area: 1 / 1 / 3 / 3;
}
.home-waiting-room-image {
	grid-area: 1 / 3 / 3 / 5;
}
.home-map {
	grid-area: 3 / 1 / 5 / 3;
}
.home-in-person-remote-options {
	grid-area: 3 / 3 / 5 / 5;
}

/* Responsive Styles */
/* Overall App */
@media (max-width: 768px) {
	.hamburger-icon {
		display: flex;
	}

	.nav-links {
		display: none;
	}

	.menu-overlay {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.5);
		display: flex;
		justify-content: center;
		align-items: center;
		z-index: 1000;
	}

	.nav-open {
		display: flex;
		flex-direction: column;
		background-color: rgba(239, 239, 238, 0.9);
		padding: 20px;
		border-radius: 10px;
		text-align: center;
		width: 85%;
		max-width: 400px;
		height: 50vh;
	}

	.nav-open ul {
		display: flex;
		flex-direction: column;
		list-style: none;
		padding: 0;
		margin: 0;
	}

	.nav-open ul li {
		margin: 15px 0;
	}

	.book-now-mobile {
		display: block;
	}

	.book-now-desktop {
		display: none;
	}
	.navigation-logo {
		width: 80%;
	}
}

/* AboutPage */
@media (max-width: 768px) {
	.about-page .section {
		flex-direction: column;
		align-items: center;
		padding: 10px;
	}

	.div1,
	.div2,
	.div3,
	.div4 {
		width: 100%;
		padding: 10px;
		box-sizing: border-box;
	}

	.aboutTextIntro {
		padding-top: 10px;
	}

	.aboutPicture,
	.aboutPictureOffice {
		width: 100%;
		max-width: 300px; /* Ensures images are not too large */
		border-radius: 10px;
		margin-bottom: 20px;
	}

	.aboutPicture {
		border-radius: 50%;
	}

	.aboutTitle h1,
	.aboutTitle h2 {
		font-size: 1.5em;
		text-align: center;
	}

	.div3 h1 {
		font-size: 1.5em;
		text-align: center;
	}

	.div3 p,
	.div1 p {
		font-size: 1em;
		text-align: justify;
		padding: 10px;
	}

	/* Ensure the button is centered */
	.button {
		margin: 0 auto;
		padding: 10px 20px;
		font-size: 1em;
	}
}

/* Ensure sections take full width */
@media (max-width: 768px) {
	.section {
		width: 100%;
		box-sizing: border-box;
		padding: 20px;
		height: auto;
	}
}

/* ContactPage */
@media (max-width: 768px) {
	.contact-page {
		display: block;
	}

	.contact-header,
	.contact-form-container,
	.contact-map-container {
		width: 100%;
		padding: 10px;
		box-sizing: border-box;
	}

	.contact-header {
		text-align: center;
	}

	.contact-form-container {
		background-color: #ffffff;
		padding: 20px;
		border-radius: 8px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
		max-width: 100%;
		margin: 0 auto;
	}
}

/* ServicesPage */
@media (max-width: 768px) {
	.services-page h1 {
		text-align: center;
		font-size: 1.5em;
	}

	.section-services {
		height: auto;
		padding: 10px;
		box-sizing: border-box;
	}
}

/* HomePage */
@media (max-width: 768px) {
	.home-grid-container {
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-gap: 10px;
	}

	.home-new-clients-info,
	.home-waiting-room-image,
	.home-map,
	.home-in-person-remote-options {
		grid-area: auto;
	}

	.home-new-clients-info {
		order: 1;
	}

	.home-waiting-room-image {
		order: 2;
	}

	.home-in-person-remote-options {
		order: 3;
	}

	.home-map {
		order: 4;
	}

	/* Specific styles for reordering elements in the welcome section */
	.section-welcome {
		display: flex;
		flex-direction: column;
	}

	.section-welcome > h1 {
		order: 1;
	}

	.section-welcome > p {
		order: 2;
	}

	/* Ensure images are responsive */
	img {
		max-width: 100%;
		height: auto;
	}

	.homePicture {
		width: 80%;
	}

	.section,
	.section-about {
		min-height: 40vh;
		padding: 10px;
		height: auto;
		flex-direction: column-reverse;
	}

	.section-about-home {
		min-height: 50vh;
		padding: 10px;
		height: auto;
		flex-direction: column;
	}

	.button {
		margin: 0 auto;
		padding: 10px 20px;
		font-size: 1em;
	}
}
/* Responsive styles for Footer */
@media (max-width: 768px) {
	.footer {
		flex-direction: column;
		padding: 20px;
	}

	.footer nav ul {
		display: none; /* Hide navigation links */
	}

	.footer .social-media-icons {
		margin-top: 10px;
	}

	.footer .social-media-icons a {
		margin: 0 10px;
	}
}
